import React from 'react'
import TextTruncate from 'react-text-truncate'

// Constants
import { THEMECONTENT } from '@constants'

// Data
import data from '../content/Suppliers-Content.json'

// Elements
import { Title, List, Par } from '@theme'
import { Layout } from '@layouts'
import { Artical } from '@sections'
import { BtnLink, BtnLinkWrapper, CompanyCard, CardWrapper } from '@components'

const Products = (props) => (
	<Layout props={props}>
		<Artical
			title="Suppliers and Products"
			par={`Pumptec is an importer of a vast range of quality products from all corners of the globe. Let the Pumptec team advise you on the best solution for your application.`}
		>
			<Title
				as="h5"
				clr="secondary"
				min={16}
				max={24}
				marginTop="4vh"
				marginBot="0.7em"
			>
				Our product range includes:
			</Title>
			<List clr={THEMECONTENT.white.par} min={14} max={20}>
				<li>
					Centrifugal and positive displacement pumps for fuels, chemicals and
					lubricants.
				</li>
				<li>Flow meters for point of sale and non point of sale application.</li>
				<li>Fuel management systems including access control and tank gauging.</li>
				<li>Specialized pumps and ancillary equipment.</li>
			</List>
		</Artical>
		<Artical theme="primary" title="Suppliers">
			<CardWrapper marginTop="2em" colRowCount={3}>
				{data.suppliers.map((supplier) => (
					<CompanyCard
						key={supplier.name}
						company={supplier.company}
						intro={
							<TextTruncate
								line={4}
								element="span"
								truncateText="…"
								text={supplier.intro}
							/>
						}
						link={`/products/${supplier.name}`}
					/>
				))}
			</CardWrapper>
		</Artical>
		<Artical par={`Pumptec provides a large variety of professional services.`}>
			<BtnLinkWrapper marginTop="6vh">
				<BtnLink title="Services Pumptec Provide" linkTo="/services" />
			</BtnLinkWrapper>
		</Artical>
	</Layout>
)

export default Products
